@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;300;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 300;
  background-color: #020202;
  color: white;
}

#canvas-container {
  position: absolute;
  width: 45rem;
  height: 45rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-self: center;
  z-index: -1;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem;
}

h1 {
  font-size: 4.3vw;
  font-family: 'Play', sans-serif;
  text-transform: uppercase;
  /* background: linear-gradient(30deg, #cb997e,#b7b7a4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

ul {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.7rem;
}

a {
  color: white;
}

a:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

@media only screen and (max-width: 992px) {
  ul {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 600px) {
  body {
    position: fixed;
    overflow: hidden;
  }

  #canvas-container {
    width: 35rem;
    height: 35rem;
  }

  h1 {
    font-size: 10.2vw;
  }

  h1 > span {
    width: 100%;
    display: inline-block;
  }

  ul {
    flex-direction: column;
    font-size: 1.1rem;
  }
}
